<template>
  <div class="intl-school">
    <skill :skill="displayObject"></skill>
  </div>
</template>

<script>
import Skill from './Skill.vue';

export default {
  name: 'intlSchool',
  components: {
    Skill,
  },
  props: ['school'],
  computed: {
    displayObject() {
      const newObj = this.school;
      newObj.display = `<div>
                          <p>
                          <strong>${this.school.school}</strong>
                          <br />
                          ${this.school.location}
                          <br />
                          ${this.school.description}
                          </p>
                        </div>`;
      return newObj;
    },
  },
};
</script>
