export default {
  degrees: [
    {
      id: 1,
      school: 'Harvard University',
      location: 'Cambridge, MA',
      degrees: [
        'Advanced doctoral study (ABD), Near Eastern Languages and Civilizations',
        'M.A., Near Eastern Languages and Civilizations',
        'M.T.S., Harvard Divinity School',
      ],
      hidden: false,
      bullets: [
        {
          display: 'Wrote 14 successful fellowship proposals to win funding for research',
          skills: {
            coding: [],
            analyzing: [],
            communicating: [0, 7],
            collaborating: [],
          },
          hidden: false,
        },
        {
          display: 'Satisfied requirements for three independent doctoral programs, learned eight languages to translate and interpret original sources of medieval Islamic and Jewish literature, passed qualifying examinations with distinction',
          skills: {
            coding: [],
            analyzing: [3, 6],
            communicating: [5, 7],
            collaborating: [],
          },
          hidden: false,
        },
        {
          display: 'Advocated for graduate student interests on departmental and university-wide committees',
          skills: {
            coding: [],
            analyzing: [],
            communicating: [5],
            collaborating: [1, 2, 5],
          },
          hidden: false,
        },
      ],
    },
    {
      id: 2,
      school: 'Swarthmore College',
      location: 'Swarthmore, PA',
      degrees: [
        'B.A. with Honors, Philosophy',
      ],
      hidden: false,
      bullets: [
        {
          display: 'Awarded National Endowment for the Humanities Younger Scholar research grant in Buddhist philosophy',
          skills: {
            coding: [],
            analyzing: [3, 6],
            communicating: [0, 7],
            collaborating: [],
          },
          hidden: false,
        },
        {
          display: 'Won Brand Blanshard Prize in Philosophy',
          skills: {
            coding: [],
            analyzing: [3, 6],
            communicating: [7],
            collaborating: [],
          },
          hidden: false,
        },
      ],
    },
  ],

  international: [
    {
      id: 1,
      school: 'Arabic Language Institute in Fez (ALIF)',
      location: 'Fez, Morocco',
      description: 'Study of Arabic language and Islamic texts',
      skills: {
        coding: [],
        analyzing: [3, 6],
        communicating: [5, 7],
        collaborating: [],
      },
      hidden: false,
    },
    {
      id: 2,
      school: 'Goethe-Institut',
      location: 'Frankfurt, Germany',
      description: 'German language study',
      skills: {
        coding: [],
        analyzing: [6],
        communicating: [5, 7],
        collaborating: [],
      },
      hidden: false,
    },
    {
      id: 3,
      school: 'Pardes Institute for Jewish Studies',
      location: 'Jerusalem, Israel',
      description: 'Study of Hebrew language and Jewish texts',
      skills: {
        coding: [],
        analyzing: [3, 6],
        communicating: [5, 7],
        collaborating: [],
      },
      hidden: false,
    },
    {
      id: 4,
      school: 'School for International Training Tibetan Studies Program and independent study in Buddhist monasteries',
      location: 'India and Nepal',
      description: 'Study of Tibetan language, religion, and history',
      skills: {
        coding: [],
        analyzing: [3, 6],
        communicating: [5, 7],
        collaborating: [],
      },
      hidden: false,
    },
  ],
};
