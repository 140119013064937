export default {
  jobs: [
    {
      title: 'Senior Software Engineer, Data Science Team',
      employer: 'Majesco',
      location: 'Middlesex, VT',
      beginYear: '2023',
      endYear: '2024',
      hidden: false,
      bullets: [
        {
          display:
            'Conceived, designed, built, and iterated on web applications to support productization of data science services',
          skills: {
            coding: [0, 1, 2, 3, 4, 6, 7, 8, 9],
            analyzing: [0],
            communicating: [5, 7],
            collaborating: [1, 2, 3, 4, 5, 7],
          },
          hidden: false,
        },
        {
          display:
            'Explored and optimized user experience for Generative AI features by building prototype for customer-facing application',
          skills: {
            coding: [0, 1, 2, 3, 4, 6, 7, 8, 9],
            analyzing: [0],
            communicating: [5, 7],
            collaborating: [1, 2, 3, 4, 5, 7],
          },
          hidden: false,
        },
        {
          display:
            'Created internal applications to enable data scientists and software engineers to develop best practices for integrating LLM technologies into products',
          skills: {
            coding: [0, 1, 2, 3, 4, 6, 7, 8, 9],
            analyzing: [0],
            communicating: [5, 7],
            collaborating: [1, 2, 3, 4, 5, 7],
          },
          hidden: false,
        },
        {
          display: 'Built interactive map and image viewers to visualize AI object detection',
          skills: {
            coding: [2, 7],
            analyzing: [0],
            communicating: [5, 7],
            collaborating: [3, 5],
          },
          hidden: false,
        },
        {
          display:
            'Developed custom component library and repeatable tools and processes for quick standup and rapid iteration of applications',
          skills: {
            coding: [2, 4, 6, 7, 8, 9],
            analyzing: [0],
            communicating: [5, 7],
            collaborating: [3, 4, 7],
          },
          hidden: false,
        },
        {
          display:
            'Understood capabilities of data science technologies and translated into UI features through creating wireframes, prioritizing development with colleagues and decision makers, and defining specifications and acceptance criteria for issue tracking',
          skills: {
            coding: [7],
            analyzing: [0, 6],
            communicating: [5, 7],
            collaborating: [1, 2, 3, 4, 5, 7],
          },
          hidden: false,
        },
      ],
    },
    {
      title: 'Principal',
      employer: 'GWVT Tech-Creative, LLC',
      location: 'Middlesex, VT',
      beginYear: '2017',
      endYear: '2022',
      hidden: false,
      bullets: [
        {
          display:
            'Collaborated with clients to help them build technology to meet their business goals',
          skills: {
            coding: [0, 1, 2, 3, 4, 6, 7, 8, 9],
            analyzing: [0, 1],
            communicating: [5, 7],
            collaborating: [0, 1, 2, 3, 4, 5, 6, 7],
          },
          hidden: false,
        },
        {
          display:
            'Created complete web applications, including UI/UX prototyping, responsive design, SPA (Single-Page Application) front end, custom data visualizations, internationalization, back-end REST API service, and database design',
          skills: {
            coding: [0, 1, 2, 3, 4, 6, 7],
            analyzing: [0],
            communicating: [],
            collaborating: [],
          },
          hidden: false,
        },
        {
          display:
            'Set up CI/CD pipelines with automated test runs and deployments; designed and implemented testing suites covering unit, integration, and end-to-end tests',
          skills: {
            coding: [8, 9],
            analyzing: [],
            communicating: [],
            collaborating: [],
          },
          hidden: false,
        },
        {
          display:
            'Created command-line tools and utilities to solve technical problems and streamline business processes',
          skills: {
            coding: [1],
            analyzing: [0],
            communicating: [],
            collaborating: [4, 5],
          },
          hidden: false,
        },
        {
          display:
            'Conducted code review to evaluate the current state of application codebase and identify potential issues',
          skills: {
            coding: [1, 2, 4, 6],
            analyzing: [],
            communicating: [],
            collaborating: [],
          },
          hidden: true,
        },
        {
          display:
            'Designed civic engagement web app that enables users to track their communications with elected officials and view personalized reports on the outcomes',
          skills: {
            coding: [6],
            analyzing: [],
            communicating: [],
            collaborating: [],
          },
          hidden: true,
        },
        {
          display:
            'Developed the above app by building the PostgreSQL database; Python admin utility, including a custom client to the ProPublica Congress API; Python back-end REST API application; and Vue front-end single-page application',
          skills: {
            coding: [0, 1, 2, 3, 4, 5, 7, 8],
            analyzing: [],
            communicating: [],
            collaborating: [3, 5, 7],
          },
          hidden: true,
        },
      ],
    },
    {
      title: 'Web Application Developer and Senior Market Research Analyst',
      employer: 'Fletcher/CSI',
      location: 'Williston, VT',
      beginYear: '2014',
      endYear: '2016',
      hidden: false,
      bullets: [
        {
          display:
            'Designed B2B web app to provide project management and data analysis tools for market research service; developed front and back ends; wrote technical documentation',
          skills: {
            coding: [0, 1, 2, 4, 6, 7],
            analyzing: [0],
            communicating: [2, 7],
            collaborating: [1, 3, 4, 5, 6, 7],
          },
          hidden: false,
        },
        {
          display:
            'Interviewed executive-level decision makers and subject-matter experts to understand customer needs for enterprise software and IT; covered solution areas in ERP, PLM, CRM, HCM, IoT, CAD, marketing, disaster recovery, and field service management',
          skills: {
            coding: [],
            analyzing: [0, 2, 3, 5],
            communicating: [5, 7],
            collaborating: [6],
          },
        },
        {
          display:
            'Managed client projects, collaborated and communicated with client and internal stakeholders, designed research methodology, wrote 200+ deliverables, managed and conducted outreach to 1000+ contacts',
          skills: {
            coding: [],
            analyzing: [2, 3, 5],
            communicating: [1, 4, 7],
            collaborating: [0, 1, 2, 3, 4, 5, 6, 7],
          },
        },
      ],
    },
    {
      title: 'Director of Communications',
      employer: 'Fletcher/CSI',
      location: 'Williston, VT',
      beginYear: '2013',
      endYear: '2014',
      hidden: false,
      bullets: [
        {
          display:
            "Customized and implemented new CRM system (SugarCRM), trained users; led initiative to integrate the company's CRM, web site, and digital marketing platforms",
          skills: {
            coding: [],
            analyzing: [0, 1],
            communicating: [5, 7],
            collaborating: [1, 2, 3, 4, 5, 6, 7],
          },
          hidden: false,
        },
        {
          display:
            'Created new company web site: wrote copy utilizing SEO strategies, designed layout and navigation, used Google Analytics to track performance, created Google AdWords campaigns, served as webmaster',
          skills: {
            coding: [7],
            analyzing: [0, 1, 2],
            communicating: [3, 6, 7],
            collaborating: [3, 5],
          },
          hidden: false,
        },
        {
          display:
            'Directed communications strategy and operations integrating marketing campaigns, lead generation, proposals, and client deliverables',
          skills: {
            coding: [],
            analyzing: [0, 1, 5],
            communicating: [0, 1, 3, 4, 5, 6, 7],
            collaborating: [1, 2, 3, 4, 5, 7],
          },
          hidden: false,
        },
        {
          display: 'Supervised direct report',
          skills: {
            coding: [],
            analyzing: [],
            communicating: [5],
            collaborating: [2, 3, 5],
          },
          hidden: false,
        },
      ],
    },
    {
      title: 'Proposal and Content Marketing Specialist',
      employer: 'Fletcher/CSI',
      location: 'Williston, VT',
      beginYear: '2012',
      endYear: '2012',
      hidden: false,
      bullets: [
        {
          display:
            'Created tracking and analysis for proposals, win rates, and revenue streams; delivered recommendations to guide company strategy',
          skills: {
            coding: [],
            analyzing: [1, 4, 5],
            communicating: [5],
            collaborating: [1, 4, 5, 7],
          },
          hidden: false,
        },
        {
          display:
            'Wrote content for 70+ proposals for projects covering wide range of industries; created proposal templates, implemented best practices in proposal development',
          skills: {
            coding: [],
            analyzing: [2, 3, 5, 6],
            communicating: [0, 4, 5, 7],
            collaborating: [0, 5],
          },
          hidden: false,
        },
        {
          display:
            'Wrote marketing content for multiple formats, including web copy, collateral, white paper, newsletter, email, press release, and social media',
          skills: {
            coding: [],
            analyzing: [0, 2, 3, 5],
            communicating: [3, 4, 5, 6, 7],
            collaborating: [1],
          },
          hidden: false,
        },
      ],
    },
    {
      title:
        'Administrator, Institute for Theory and Computation, Harvard-Smithsonian Center for Astrophysics',
      employer: 'Harvard University',
      location: 'Cambridge, MA',
      beginYear: '2008',
      endYear: '2011',
      hidden: false,
      bullets: [
        {
          display:
            'Managed operations for the leading institute for theoretical astrophysics in North America; streamlined processes through team building with university administrators and faculty',
          skills: {
            coding: [],
            analyzing: [0],
            communicating: [5],
            collaborating: [1, 2, 3, 4, 5, 7],
          },
          hidden: false,
        },
        {
          display:
            'Handled communications by writing and editing web content, publicity materials, formal correspondence, and technical science writing; served as webmaster',
          skills: {
            coding: [],
            analyzing: [],
            communicating: [2, 3, 4, 7],
            collaborating: [1, 3],
          },
          hidden: false,
        },
        {
          display:
            'Managed $1M annual budget, developed forecasting models to maximize use of available funds',
          skills: {
            coding: [],
            analyzing: [4],
            communicating: [],
            collaborating: [1, 4, 5, 7],
          },
          hidden: false,
        },
      ],
    },
    {
      title: 'Teaching Fellow and Lecturer',
      employer: 'Harvard University and Boston University',
      location: 'Cambridge and Boston, MA',
      beginYear: '2000',
      endYear: '2007',
      hidden: true,
      bullets: [
        {
          display:
            'Developed curriculum for 11 courses in writing, Islam, and Judaism; lectured and led discussions',
          skills: {
            coding: [],
            analyzing: [3, 6],
            communicating: [5, 7],
            collaborating: [2, 5],
          },
          hidden: false,
        },
        {
          display:
            'Designed assignments with clear goals to improve students’ writing skills and understanding of subject matter',
          skills: {
            coding: [],
            analyzing: [1],
            communicating: [4, 7],
            collaborating: [7],
          },
          hidden: false,
        },
        {
          display:
            'Guided students in regular individual consultations, received high praise in anonymous evaluations',
          skills: {
            coding: [],
            analyzing: [],
            communicating: [5, 7],
            collaborating: [5],
          },
          hidden: false,
        },
      ],
    },
    {
      title: 'Editorial Assistant',
      employer: 'Harvard Theological Review',
      location: 'Cambridge, MA',
      beginYear: '2002',
      endYear: '2004',
      hidden: true,
      bullets: [
        {
          display:
            'Evaluated article submissions in wide range of specializations for originality of scholarship',
          skills: {
            coding: [],
            analyzing: [3, 6],
            communicating: [],
            collaborating: [],
          },
          hidden: false,
        },
        {
          display: 'Wrote reviews of submissions and recommended changes for publication',
          skills: {
            coding: [],
            analyzing: [],
            communicating: [4, 7],
            collaborating: [],
          },
          hidden: false,
        },
      ],
    },
  ],
};
