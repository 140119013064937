export default {
  activities: [
    {
      role: 'Co-Organizer',
      title: '',
      context: 'Burlington JavaScript Meetup',
      date: '2016–2019',
      skills: {
        coding: [],
        analyzing: [],
        communicating: [5],
        collaborating: [2],
      },
      hidden: false,
    },
    {
      role: 'Presentation',
      title: '"Thirteen Ways of Using Vue.js"',
      context: 'Granite State Code Camp',
      date: '11/19',
      skills: {
        coding: [2, 4],
        analyzing: [],
        communicating: [5, 7],
        collaborating: [],
      },
      resources: [
        {
          type: 'code',
          url: 'https://github.com/gwvt/thirteen-vue-spa',
        },
      ],
      hidden: false,
    },
    {
      role: 'Presentation',
      title: '"Thirteen Ways of Using Vue.js"',
      context: 'Upper Valley (VT/NH) JavaScript Meetup',
      date: '10/18',
      skills: {
        coding: [2, 4],
        analyzing: [],
        communicating: [5, 7],
        collaborating: [],
      },
      resources: [
        {
          type: 'code',
          url: 'https://github.com/gwvt/thirteen-vue-spa',
        },
      ],
      hidden: false,
    },
    {
      role: 'Presentation',
      title: '"Thirteen Ways of Using Vue.js"',
      context: 'Burlington JavaScript Meetup',
      date: '10/18',
      skills: {
        coding: [2, 4],
        analyzing: [],
        communicating: [5, 7],
        collaborating: [],
      },
      resources: [
        {
          type: 'code',
          url: 'https://github.com/gwvt/thirteen-vue-spa',
        },
      ],
      hidden: false,
    },
    {
      role: 'Presentation',
      title: '"An Introduction to End-to-End Testing"',
      context: 'Upper Valley (VT/NH) JavaScript Meetup',
      date: '5/18',
      skills: {
        coding: [1, 3, 4, 8],
        analyzing: [],
        communicating: [5, 7],
        collaborating: [],
      },
      resources: [
        {
          type: 'code',
          url: 'https://github.com/gwvt/e2e-intro',
        },
      ],
      hidden: false,
    },
    {
      role: 'Lightning Talk',
      title: '"Composable End-to-End Testing: One Approach"',
      context: 'Burlington JavaScript Meetup',
      date: '3/18',
      skills: {
        coding: [1, 3, 4, 8],
        analyzing: [],
        communicating: [5, 7],
        collaborating: [],
      },
      resources: [
        {
          type: 'code',
          url: 'https://github.com/gwvt/composing-e2e-tests',
        },
      ],
      hidden: false,
    },
    {
      role: 'Moderator',
      title: '"Professional Development Panel Discussion"',
      context: 'Burlington JavaScript Meetup',
      date: '12/17',
      skills: {
        coding: [],
        analyzing: [],
        communicating: [5],
        collaborating: [2],
      },
      hidden: false,
    },
    {
      role: 'Presentation',
      title: '"Python Principles with Tkinter"',
      context: 'Upper Valley (VT/NH) Data Science Meetup',
      date: '9/17',
      skills: {
        coding: [6, 7],
        analyzing: [],
        communicating: [5, 7],
        collaborating: [],
      },
      resources: [
        {
          type: 'code',
          url: 'https://github.com/gwvt/spirit-of-tkinter',
        },
      ],
      hidden: false,
    },
    {
      role: 'Lightning Talk',
      title: '"Testing APIs with Postman"',
      context: 'Vermont Coders Connection Meetup',
      date: '9/17',
      skills: {
        coding: [1, 3, 4, 8],
        analyzing: [],
        communicating: [5, 7],
        collaborating: [],
      },
      hidden: false,
    },
    {
      role: 'Presentation',
      title: '"Building Desktop GUIs with Tkinter"',
      context: 'Burlington Python Meetup',
      date: '8/17',
      skills: {
        coding: [6, 7],
        analyzing: [],
        communicating: [5, 7],
        collaborating: [],
      },
      resources: [
        {
          type: 'code',
          url: 'https://github.com/gwvt/spirit-of-tkinter',
        },
      ],
      hidden: false,
    },
    {
      role: 'Teaching Assistant',
      title: '<em>Intro to HTML/CSS</em>',
      context: 'Girl Develop It Burlington',
      date: '7/17',
      skills: {
        coding: [2],
        analyzing: [],
        communicating: [5, 7],
        collaborating: [],
      },
      hidden: true,
    },
    {
      role: 'Teaching Assistant',
      title: '<em>Intro to JavaScript</em>',
      context: 'Girl Develop It Burlington',
      date: '3/17',
      skills: {
        coding: [2],
        analyzing: [],
        communicating: [5, 7],
        collaborating: [],
      },
      hidden: true,
    },
    {
      role: 'Discussion Leader',
      title: '"BTVJS Book Club: Kyle Simpson, <em>You Don\'t Know JavaScript: this & Object Prototypes</em>"',
      context: 'Burlington JavaScript Meetup',
      date: '2/17',
      skills: {
        coding: [2],
        analyzing: [],
        communicating: [5, 7],
        collaborating: [],
      },
      hidden: false,
    },
  ],
};
