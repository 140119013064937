<template>
  <div class="column is-6">
    <div class="columns is-mobile is-multiline">
      <div class="column is-8-mobile is-12-desktop is-8-fullhd">
        <strong><p>{{ degree.school }}</p></strong>
        <p v-for="degree in degree.degrees" :key="degree.id">{{ degree }}</p>
      </div>
      <div class="column is-4-mobile is-12-desktop is-4-fullhd">
        <p class="degree-header-right">{{ degree.location }}</p>
      </div>
    </div>
    <div class="content">
      <skill v-for="bullet in filterHidden(degree.bullets)"
      :skill="addClass(bullet, degree.class)" :key="bullet.display"></skill>
    </div>
  </div>
</template>

<script>
import helpers from '../../../helpers';

import Skill from './Skill.vue';

export default {
  name: 'degree',
  components: {
    Skill,
  },
  props: ['degree'],
  data() {
    return {
      filterHidden: helpers.filterHidden,
      addClass: helpers.addClass,
    };
  },
};
</script>
