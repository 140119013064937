<template>
  <div id="app">
    <div id="header">
      <h1 class="title is-1" style="margin-bottom: .8rem;">Gregory White</h1>
      <h2 class="title is-2">Software Engineer</h2>
      <div style="display: flex; width: 100%; justify-content: flex-end; margin-bottom: .5rem;">
      <p>
        <a href="javascript:location=
        'mailto:\u0067\u0072\u0065\u0067\u0040\u0067\u0077\u0076\u0074\u002e\u006d\u0065';void 0">
          email
        </a>
        &nbsp;•&nbsp;
        <a href="https://github.com/gwvt/" target="_blank">GitHub</a>
        &nbsp;•&nbsp;
        <a href="https://www.linkedin.com/in/gwvt1/" target="_blank">
          LinkedIn
        </a>
        &nbsp;•&nbsp;
        <a href="https://gwvt.io" target="_blank">GWVT Tech-Creative</a>
      </p>
      </div>
    </div>
    <div>
      <Resume style="margin-top: 1rem;"/>
    </div>
    <div id="footer">
      <div style="text-align: center;">
      <p>
        made with <a href="https://vuejs.org">Vue</a> + <a href="http://bulma.io">Bulma</a> + <a href="https://jenil.github.io/bulmaswatch">Bulmaswatch</a>
      </p>
      </div>
    </div>
  </div>
</template>

<script>
import Resume from '@/components/resume/Resume.vue';

export default {
  name: 'App',
  components: {
    Resume,
  },
};
</script>

<style lang="scss">
@import "~bulmaswatch/yeti/variables";

@import "~bulma/sass/utilities/_all";
@import "~bulma/sass/base/_all";

@import "~bulma/sass/grid/columns";
@import "~bulma/sass/elements/title";
@import "~bulma/sass/elements/content";
@import "~bulma/sass/elements/button";

@import "~bulmaswatch/yeti/overrides";
</style>

<style>
#app {
  margin: 2rem;
}

#header {
  text-align: right;
}

#coding-categories-row {
  margin-bottom: 1.5rem;
}
#footer {
  margin-top: 3rem;
  text-align: center;
}
.resume-section {
  height: 100vh;
  overflow-y: scroll;
  /* padding: 1rem; */
  /* border: 1px solid #F2F2F2; */
}
.skill-section {
  margin: 1rem 0rem 0rem 0rem;
}
.content-header {
  margin: 1rem 0rem 1.5rem 0rem;
}
.skill {
  margin: .2rem;
  white-space: normal;
  text-align: left;
  height: 100%;
}
.skill-highlighted {
  border: 1px solid black !important;
}
.job-header-right {
  text-align: right;
}
.degree-header-right {
  text-align: right;
}
.intl-school {
  margin-bottom: .5rem;
}
.community-section {
  margin: 2rem 0 1.4rem 0;
}
.community {
  margin: 0 .3rem .3rem 0;
}
</style>
