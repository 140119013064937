<template>
  <div class="column is-6-desktop is-4-fullhd">
    <div class="columns is-mobile is-multiline">
      <div class="column is-8">
        <p><strong>{{ job.title }}</strong></p>
        <p>{{ job.employer }}</p>
      </div>
      <div class="column is-4">
        <p class="job-header-right">{{ job.location }}</p>
        <p class="job-header-right">
          <span>{{ job.beginYear }}</span>–{{ job.endYear }}
        </p>
      </div>
    </div>
    <div class="content">
      <skill v-for="bullet in filterHidden(job.bullets)"
      :skill="addClass(bullet, job.class)" :key="bullet.display"></skill>
    </div>
  </div>
</template>

<script>
import helpers from '../../../helpers';

import Skill from './Skill.vue';

export default {
  name: 'job',
  components: {
    Skill,
  },
  props: ['job'],
  data() {
    return {
      filterHidden: helpers.filterHidden,
      addClass: helpers.addClass,
    };
  },
};
</script>
