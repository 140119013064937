export default {
  skills: {
    coding: {
      'is-primary': true,
    },
    analyzing: {
      'is-info': true,
    },
    communicating: {
      'is-success': true,
    },
    collaborating: {
      'is-warning': true,
    },
  },
  coding: {
    codingCategories: {
      language: {
        'is-primary': true,
        'is-outlined': true,
      },
      dialect: {
        'is-info': true,
        'is-outlined': true,
      },
      library: {
        'is-success': true,
        'is-outlined': true,
      },
      tool: {
        'is-warning': true,
        'is-outlined': true,
      },
    },
    language: {
      'is-primary': true,
      'is-outlined': true,
    },
    dialect: {
      'is-info': true,
      'is-outlined': true,
    },
    library: {
      'is-success': true,
      'is-outlined': true,
    },
    tool: {
      'is-warning': true,
      'is-outlined': true,
    },
  },
  work: {
    job: {
      bullet: {
        'is-white': true,
      },
    },
  },
  education: {
    degree: {
      bullet: {
        'is-white': true,
      },
    },
    intlSchool: {
      'is-white': true,
    },
  },
  community: {
    activity: {
      'is-white': true,
    },
  },
};
