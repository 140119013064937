export default {
  coding: [
    {
      display: 'web front end',
      index: 2,
      category: 'coding',
    },
    {
      display: 'web back end',
      index: 1,
      category: 'coding',
    },
    {
      display: 'testing',
      index: 8,
      category: 'coding',
    },
    {
      display: 'UI/UX',
      index: 7,
      category: 'coding',
    },
    {
      display: 'REST APIs',
      index: 3,
      category: 'coding',
    },
    {
      display: 'databases',
      index: 0,
      category: 'coding',
    },
    {
      display: 'CI/CD',
      index: 9,
      category: 'coding',
    },
    {
      display: 'architecture',
      index: 6,
      category: 'coding',
    },
    {
      display: 'SaaS/web applications',
      index: 4,
      category: 'coding',
    },
  ],
  analyzing: [
    {
      display: 'gathering business requirements',
      index: 0,
      category: 'analyzing',
    },
    {
      display: 'performance metrics',
      index: 1,
      category: 'analyzing',
    },
    {
      display: 'competitive analysis',
      index: 2,
      category: 'analyzing',
    },
    {
      display: 'qualitative research',
      index: 3,
      category: 'analyzing',
    },
    {
      display: 'financial analysis',
      index: 4,
      category: 'analyzing',
    },
    {
      display: 'business strategy',
      index: 5,
      category: 'analyzing',
    },
    {
      display: 'cross-disciplinary thinking',
      index: 6,
      category: 'analyzing',
    },
  ],
  communicating: [
    {
      display: 'proposals',
      index: 0,
      category: 'communicating',
    },
    {
      display: 'reports',
      index: 1,
      category: 'communicating',
    },
    {
      display: 'technical writing',
      index: 2,
      category: 'communicating',
    },
    {
      display: 'web & marketing copy',
      index: 3,
      category: 'communicating',
    },
    {
      display: 'editing',
      index: 4,
      category: 'communicating',
    },
    {
      display: 'dialogue & exchange of ideas',
      index: 5,
      category: 'communicating',
    },
    {
      display: 'branding & messaging',
      index: 6,
      category: 'communicating',
    },
    {
      display: 'communicating complex information',
      index: 7,
      category: 'communicating',
    },
  ],
  collaborating: [
    {
      display: 'client relations',
      index: 0,
      category: 'collaborating',
    },
    {
      display: 'stakeholder engagement',
      index: 1,
      category: 'collaborating',
    },
    {
      display: 'team building',
      index: 2,
      category: 'collaborating',
    },
    {
      display: 'project management',
      index: 3,
      category: 'collaborating',
    },
    {
      display: 'streamlining processes',
      index: 4,
      category: 'collaborating',
    },
    {
      display: 'problem solving',
      index: 5,
      category: 'collaborating',
    },
    {
      display: 'continuous improvement',
      index: 7,
      category: 'collaborating',
    },
    {
      display: 'facilitating technical/non-technical cooperation',
      index: 6,
      category: 'collaborating',
    },
  ],
};
