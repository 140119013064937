<template>
    <skill :class="{ community: true }" :skill="displayObject"></skill>
</template>

<script>
import Skill from './Skill.vue';

export default {
  name: 'community',
  components: {
    Skill,
  },
  props: ['community'],
  computed: {
    colonSeparator() {
      let separator = '';
      if (this.community.title) {
        separator = ':';
      }
      return separator;
    },
    displayObject() {
      const newObj = this.community;
      let resources = '';
      if (newObj.resources) {
        newObj.resources.forEach((r) => {
          resources += `<a href="${r.url}">${r.type}</a> `;
        });
        resources = resources.trim();
        if (resources.length) {
          resources = `<br />${resources}`;
        }
      }
      newObj.display = `<div>
        <p>
          <strong>${this.community.role}</strong>${this.colonSeparator} ${this.community.title}
          <br />
          ${this.community.context}, ${this.community.date}
          ${resources}
        </p>
        </div>`;
      return newObj;
    },
  },
};
</script>
