export default {
  filterHidden(arr) {
    const newArr = arr.filter((el) => !el.hidden);
    return newArr;
  },
  addClass(skill, classObj) {
    const newSkill = skill;
    newSkill.class = classObj;
    return newSkill;
  },
  copyObject(obj) {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      newObj[key] = obj[key];
    });
    return newObj;
  },
  ifPropExistsIncludesVal(obj, prop, val) {
    let retVal = false;
    if (obj && obj[prop] && obj[prop].includes(val)) {
      retVal = true;
    }
    return retVal;
  },
};
