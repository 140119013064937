<template>
  <a class="button skill" :class="currentClass" @mouseover="mouseoverSkill"
  @mouseout="mouseoutSkill" @click="clickSkill" v-html="skill.display"></a>
</template>

<script>
import bus from '../../../bus';
import helpers from '../../../helpers';

export default {
  name: 'skill',
  props: ['skill'],
  data() {
    return {
      clickMode: false,
      currentClass: null,
    };
  },
  methods: {
    skillsToExperienceHighlight(skill) {
      return helpers.ifPropExistsIncludesVal(
        this.skill.skills, skill.category, skill.index,
      );
    },
    experienceToSkillsHighlight(skill) {
      return this.skill.category
        && helpers.ifPropExistsIncludesVal(
          skill.skills, this.skill.category, this.skill.index,
        );
    },
    codingCategoryToCodingExperienceHighlight(skill) {
      return this.skill.codingCategory
        && this.skill.codingCategory === skill.selectorName;
    },
    codingExperienceToCodingCategoryHighlight(skill) {
      return this.skill.selectorName
        && this.skill.selectorName === skill.codingCategory;
    },
    codingLanguageToCodingExperienceHighlight(skill) {
      return helpers.ifPropExistsIncludesVal(
        this.skill, 'languages', skill.display,
      );
    },
    codingExperienceToCodingLanguageHighlight(skill) {
      return this.skill.codingCategory === 'language'
        && helpers.ifPropExistsIncludesVal(skill, 'languages', this.skill.display);
    },
    checkAllHighlightConditions(skill) {
      let retVal = [false, ''];
      if (this.skillsToExperienceHighlight(skill)) {
        retVal = [true, 'skillsToExperience'];
      } else if (this.experienceToSkillsHighlight(skill)) {
        retVal = [true, 'experienceToSkills'];
      } else if (this.codingCategoryToCodingExperienceHighlight(skill)
          || this.codingExperienceToCodingCategoryHighlight(skill)
          || this.codingLanguageToCodingExperienceHighlight(skill)
          || this.codingExperienceToCodingLanguageHighlight(skill)) {
        retVal = [true, 'codingCategoryLanguageOrExperienceToTheOther'];
      }

      return retVal;
    },

    checkThisSkillForHighlighting(skill, clicked) {
      const highlightBoolAndCondition = this.checkAllHighlightConditions(skill);
      if (highlightBoolAndCondition[0]) {
        this.highlightThisSkill(skill, highlightBoolAndCondition[1]);
      }

      if (clicked) {
        this.clickMode = true;
      }
    },
    highlightThisSkill(skill, highlightCondition) {
      if (highlightCondition === 'codingCategoryLanguageOrExperienceToTheOther') {
        this.currentClass = helpers.copyObject(this.skill.class);
        this.currentClass['is-outlined'] = false;
      } else if (highlightCondition === 'experienceToSkills') {
        this.currentClass = helpers.copyObject(this.skill.class);
      } else {
        this.currentClass = helpers.copyObject(skill.class);
      }

      this.currentClass['is-hovered'] = true;
      this.currentClass['skill-highlighted'] = true;
    },
    removeHighlightThisSkill(clicked) {
      this.currentClass = helpers.copyObject(this.skill.class);
      if (clicked) {
        this.clickMode = false;
      }
    },

    mouseoverSkill() {
      if (!this.clickMode) {
        bus.$emit('highlightSkills', this.skill);
      }
    },
    mouseoutSkill() {
      if (!this.clickMode) {
        bus.$emit('removeHighlights');
      }
    },
    clickSkill() {
      if (this.clickMode) {
        bus.$emit('removeHighlights', true);
      } else {
        this.currentClass = {};
        this.currentClass['is-danger'] = true;
        this.currentClass['is-hovered'] = true;
        bus.$emit('highlightSkills', this.skill, true);
      }
    },
  },
  created() {
    this.currentClass = helpers.copyObject(this.skill.class);
    bus.$on('highlightSkills', (skill, clicked) => {
      this.checkThisSkillForHighlighting(skill, clicked);
    });
    bus.$on('removeHighlights', (clicked) => {
      this.removeHighlightThisSkill(clicked);
    });
  },
};
</script>
