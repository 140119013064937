<template>
  <div>
    <div class="columns is-desktop">
      <div class="column is-4-desktop is-3-widescreen">
        <h1 class="title is-1">Skills</h1>
        <div class="resume-section">
          <div class="skill-section">
            <h3 class="title is-3">Coding</h3>
            <skill
              v-for="skill in skills.coding"
              :skill="addClass(skill, classes.skills.coding)"
              :key="skill.display"
            ></skill>
          </div>
          <div class="skill-section">
            <h3 class="title is-3">Analyzing</h3>
            <skill
              v-for="skill in skills.analyzing"
              :skill="addClass(skill, classes.skills.analyzing)"
              :key="skill.display"
            ></skill>
          </div>
          <div class="skill-section">
            <h3 class="title is-3">Communicating</h3>
            <skill
              v-for="skill in skills.communicating"
              :skill="addClass(skill, classes.skills.communicating)"
              :key="skill.display"
            ></skill>
          </div>
          <div class="skill-section">
            <h3 class="title is-3">Collaborating</h3>
            <skill
              v-for="skill in skills.collaborating"
              :skill="addClass(skill, classes.skills.collaborating)"
              :key="skill.display"
            ></skill>
          </div>
        </div>
      </div>

      <div id="content" class="column">
        <h1 class="title is-1">Experience</h1>
        <div class="resume-section">
          <div class="content-header">
            <h2 class="title is-2">Coding</h2>
          </div>
          <div id="coding-categories-row">
            <skill
              v-for="category in codingCategoriesWithClasses"
              :skill="category"
              :key="category.display"
            ></skill>
          </div>
          <template v-for="language in filterHidden(coding.languages)">
            <div :key="language.display">
              <skill :skill="addClass(language, classes.coding.language)"></skill>
              <skill
                v-for="dialectVersion in filterHidden(language.dialectsVersions)"
                :skill="addClass(dialectVersion, classes.coding.dialect)"
                :key="dialectVersion.display"
              ></skill>
              <skill
                v-for="library in filterHidden(language.libraries)"
                :skill="addClass(library, classes.coding.library)"
                :key="library.display"
              ></skill>
            </div>
          </template>
          <div>
            <skill
              v-for="tool in filterHidden(coding.tools)"
              :skill="addClass(tool, classes.coding.tool)"
              :key="tool.display"
            ></skill>
          </div>

          <div class="content-header">
            <h2 class="title is-2">Working</h2>
          </div>
          <div class="columns is-desktop is-multiline" style="width: 100%;">
            <job
              v-for="job in jobsNoHidden"
              :job="addClass(job, classes.work.job.bullet)"
              :key="`${job.beginYear}-${job.title}`"
            ></job>
          </div>

          <div class="content-header">
            <h2 class="title is-2">Serving Community</h2>
          </div>
          <div class="community-section">
            <community
              v-for="community in communityNoHidden"
              :community="addClass(community, classes.community.activity)"
              :key="`${community.date}-${community.context}`"
            ></community>
          </div>

          <div class="columns is-desktop is-multiline" style="width: 100%;">
            <div class="column is-12-desktop is-8-widescreen">
              <div class="content-header">
                <h2 class="title is-2">Learning</h2>
              </div>
              <div class="columns">
                <degree
                  v-for="degree in filterHidden(education.degrees)"
                  :degree="addClass(degree, classes.education.degree.bullet)"
                  :key="`${degree.school}-${degree.degrees[0]}`"
                ></degree>
              </div>
            </div>

            <div class="column is-12-desktop is-4-widescreen">
              <div class="content-header">
                <h2 class="title is-2">Learning Abroad</h2>
              </div>
              <intl-school
                v-for="school in filterHidden(education.international)"
                :school="addClass(school, classes.education.intlSchool)"
                :key="`${school.school}`"
              ></intl-school>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from '../../helpers';

import skills from '../../data/skills';
import coding from '../../data/coding';
import work from '../../data/work';
import community from '../../data/community';
import education from '../../data/education';
import classes from '../../data/classes';

import Skill from './comps/Skill.vue';
import Job from './comps/Job.vue';
import Community from './comps/Community.vue';
import Degree from './comps/Degree.vue';
import IntlSchool from './comps/IntlSchool.vue';

export default {
  name: 'resume',
  components: {
    Skill,
    Job,
    Community,
    Degree,
    IntlSchool,
  },
  data() {
    return {
      skills,
      coding,
      work,
      education,
      classes,
      filterHidden: helpers.filterHidden,
      addClass: helpers.addClass,
    };
  },
  computed: {
    jobsNoHidden() {
      return this.filterHidden(work.jobs);
    },
    communityNoHidden() {
      return this.filterHidden(community.activities);
    },
    codingCategoriesWithClasses() {
      const newArr = this.coding.codingCategories.map((el) => {
        const newEl = this.addClass(el, this.classes.coding.codingCategories[el.selectorName]);
        return newEl;
      });
      return newArr;
    },
  },
};
</script>
