export default {
  codingCategories: [
    {
      display: 'Languages',
      selectorName: 'language',
    },
    {
      display: 'Dialects/versions',
      selectorName: 'dialect',
    },
    {
      display: 'Libraries/frameworks',
      selectorName: 'library',
    },
    {
      display: 'Ecosystem',
      selectorName: 'tool',
    },
  ],

  languages: [
    {
      display: 'HTML/CSS',
      codingCategory: 'language',
      skills: {
        coding: [2, 4, 7],
      },
      hidden: false,
      dialectsVersions: [
        {
          display: 'HTML5',
          codingCategory: 'dialect',
          languages: ['HTML/CSS'],
          skills: {
            coding: [2, 4, 7],
          },
          hidden: false,
        },
        {
          display: 'CSS3+',
          codingCategory: 'dialect',
          languages: ['HTML/CSS'],
          skills: {
            coding: [2, 4, 7],
          },
          hidden: false,
        },
        {
          display: 'Sass',
          codingCategory: 'dialect',
          languages: ['HTML/CSS'],
          skills: {
            coding: [2, 4, 7],
          },
          hidden: false,
        },
      ],
      libraries: [
        {
          display: 'Tailwind CSS',
          codingCategory: 'library',
          languages: ['HTML/CSS'],
          skills: {
            coding: [2, 4, 7],
          },
          hidden: false,
        },
        {
          display: 'Bootstrap',
          codingCategory: 'library',
          languages: ['HTML/CSS'],
          skills: {
            coding: [2, 4, 7],
          },
          hidden: false,
        },
        {
          display: 'Bulma',
          codingCategory: 'library',
          languages: ['HTML/CSS'],
          skills: {
            coding: [2, 4, 7],
          },
          hidden: false,
        },
      ],
    },
    {
      display: 'JavaScript',
      codingCategory: 'language',
      skills: {
        coding: [1, 2, 3, 4, 7, 8],
      },
      hidden: false,
      dialectsVersions: [
        {
          display: 'TypeScript',
          codingCategory: 'dialect',
          languages: ['JavaScript'],
          skills: {
            coding: [1, 2, 4],
          },
          hidden: false,
        },
        {
          display: 'ES6+',
          codingCategory: 'dialect',
          languages: ['JavaScript'],
          skills: {
            coding: [1, 2, 4],
          },
          hidden: true,
        },
        {
          display: 'Node',
          codingCategory: 'dialect',
          languages: ['JavaScript'],
          skills: {
            coding: [1, 2, 4],
          },
          hidden: false,
        },
        {
          display: 'Vanilla JS',
          codingCategory: 'dialect',
          languages: ['JavaScript'],
          skills: {
            coding: [1, 2, 4],
          },
          hidden: false,
        },
      ],
      libraries: [
        {
          display: 'Vue 2',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4],
          },
          hidden: false,
        },
        {
          display: 'Vue 3 with Composition API',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4],
          },
          hidden: false,
        },
        {
          display: 'Vuex',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4],
          },
          hidden: false,
        },
        {
          display: 'Pinia',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4],
          },
          hidden: false,
        },
        {
          display: 'vue-resource',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4],
          },
          hidden: true,
        },
        {
          display: 'Vue Router',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4],
          },
          hidden: false,
        },
        {
          display: 'BootstrapVue',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4, 7],
          },
          hidden: false,
        },
        {
          display: 'Vuetify',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4, 7],
          },
          hidden: false,
        },
        {
          display: 'Buefy',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4, 7],
          },
          hidden: false,
        },
        {
          display: 'vue-auth',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4],
          },
          hidden: true,
        },
        {
          display: 'D3',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4, 7],
          },
          hidden: false,
        },
        {
          display: 'Vue I18n',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4],
          },
          hidden: false,
        },
        {
          display: 'Mock Service Worker',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4],
          },
          hidden: false,
        },
        {
          display: 'Storybook',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4, 7],
          },
          hidden: false,
        },
        {
          display: 'Express',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [1, 3, 4],
          },
          hidden: true,
        },
        {
          display: 'Feathers',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [1, 3, 4],
          },
          hidden: false,
        },
        {
          display: 'Knex',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [0, 1, 3, 4],
          },
          hidden: false,
        },
        {
          display: 'WebdriverIO',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [4, 8],
          },
          hidden: true,
        },
        {
          display: 'Nightwatch',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [4, 8],
          },
          hidden: true,
        },
        {
          display: 'Jest',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [1, 2, 4, 8],
          },
          hidden: false,
        },
        {
          display: 'Mocha',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [1, 2, 4, 8],
          },
          hidden: false,
        },
        {
          display: 'Karma',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [1, 2, 4, 8],
          },
          hidden: true,
        },
        {
          display: 'Chai',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [1, 2, 4, 8],
          },
          hidden: false,
        },
        {
          display: 'Cypress',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4, 8],
          },
          hidden: false,
        },
        {
          display: 'Axios',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [1, 2, 3, 4],
          },
          hidden: false,
        },
        {
          display: 'SuperAgent',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [1, 2, 3, 4],
          },
          hidden: false,
        },
        {
          display: 'SuperTest',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [1, 3, 4, 8],
          },
          hidden: false,
        },
        {
          display: 'jwt-simple',
          languages: ['JavaScript'],
          codingCategory: 'library',
          skills: {
            coding: [1, 2, 3, 4],
          },
          hidden: true,
        },
        {
          display: 'bcryptjs',
          languages: ['JavaScript'],
          codingCategory: 'library',
          skills: {
            coding: [1, 3, 4],
          },
          hidden: true,
        },
        {
          display: 'Moment',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [1, 2, 4],
          },
          hidden: true,
        },
        {
          display: 'Raphaël/g.Raphaël',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4],
          },
          hidden: true,
        },
        {
          display: 'Papa Parse',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4],
          },
          hidden: true,
        },
        {
          display: 'JSPath',
          codingCategory: 'library',
          languages: ['JavaScript'],
          skills: {
            coding: [2, 4],
          },
          hidden: true,
        },
      ],
    },
    {
      display: 'Python',
      codingCategory: 'language',
      skills: {
        coding: [1, 3, 4, 8],
      },
      hidden: false,
      dialectsVersions: [
        {
          display: 'Python 2.7',
          codingCategory: 'dialect',
          languages: ['Python'],
          skills: {
            coding: [1, 3, 4],
          },
          hidden: true,
        },
        {
          display: 'Python 3.6',
          codingCategory: 'dialect',
          languages: ['Python'],
          skills: {
            coding: [1, 3, 4],
          },
          hidden: true,
        },
      ],
      libraries: [
        {
          display: 'Flask',
          languages: ['Python'],
          codingCategory: 'library',
          skills: {
            coding: [1, 3, 4],
          },
          hidden: false,
        },
        {
          display: 'Flask-RESTful',
          languages: ['Python'],
          codingCategory: 'library',
          skills: {
            coding: [1, 3, 4],
          },
          hidden: true,
        },
        {
          display: 'Flask-CORS',
          languages: ['Python'],
          codingCategory: 'library',
          skills: {
            coding: [1, 3, 4],
          },
          hidden: true,
        },
        {
          display: 'SQLAlchemy',
          languages: ['Python'],
          codingCategory: 'library',
          skills: {
            coding: [0, 1, 3, 4],
          },
          hidden: false,
        },
        {
          display: 'Beautiful Soup',
          languages: ['Python'],
          codingCategory: 'library',
          skills: {
            coding: [1, 4],
          },
          hidden: false,
        },
        {
          display: 'Requests',
          languages: ['Python'],
          codingCategory: 'library',
          skills: {
            coding: [1, 3, 4],
          },
          hidden: false,
        },
        {
          display: 'PyJWT',
          languages: ['Python'],
          codingCategory: 'library',
          skills: {
            coding: [1, 4],
          },
          hidden: true,
        },
        {
          display: 'bcrypt',
          languages: ['Python'],
          codingCategory: 'library',
          skills: {
            coding: [1, 3, 4],
          },
          hidden: true,
        },
        {
          display: 'cryptography',
          languages: ['Python'],
          codingCategory: 'library',
          skills: {
            coding: [1, 3, 4],
          },
          hidden: true,
        },
        {
          display: 'pytest',
          languages: ['Python'],
          codingCategory: 'library',
          skills: {
            coding: [1, 4, 8],
          },
          hidden: false,
        },
        {
          display: 'Tkinter',
          languages: ['Python'],
          codingCategory: 'library',
          skills: {
            coding: [7],
          },
          hidden: false,
        },
      ],
    },
    {
      display: 'SQL',
      codingCategory: 'language',
      skills: {
        coding: [0, 1, 3, 4],
      },
      hidden: false,
      dialectsVersions: [
        {
          display: 'PostgreSQL',
          languages: ['SQL'],
          codingCategory: 'dialect',
          skills: {
            coding: [0, 1, 3, 4],
          },
          hidden: false,
        },
        {
          display: 'Cosmos DB',
          languages: ['SQL'],
          codingCategory: 'dialect',
          skills: {
            coding: [0, 1, 3, 4],
          },
          hidden: false,
        },
        {
          display: 'SQLite',
          languages: ['SQL'],
          codingCategory: 'dialect',
          skills: {
            coding: [0, 1, 3, 4],
          },
          hidden: false,
        },
      ],
      libraries: [],
    },
    {
      display: 'Command Line',
      codingCategory: 'language',
      skills: {
        coding: [0, 1, 2, 3, 4, 8, 9],
      },
      hidden: false,
      dialectsVersions: [
        {
          display: 'Bash',
          codingCategory: 'dialect',
          languages: ['Command Line'],
          skills: {
            coding: [0, 1, 2, 3, 4],
          },
          hidden: false,
        },
      ],
      libraries: [],
    },
    {
      display: 'YAML',
      codingCategory: 'language',
      skills: {
        coding: [0, 1, 2, 3, 4, 8, 9],
      },
      hidden: false,
      dialectsVersions: [],
      libraries: [],
    },
  ],

  tools: [
    {
      display: 'Git',
      codingCategory: 'tool',
      languages: ['Command Line', 'SQL', 'Python', 'JavaScript', 'HTML/CSS'],
      skills: {
        coding: [0, 1, 2, 3, 4, 9],
      },
      hidden: false,
    },
    {
      display: 'VS Code',
      codingCategory: 'tool',
      languages: ['Command Line', 'SQL', 'Python', 'JavaScript', 'HTML/CSS'],
      skills: {
        coding: [0, 1, 2, 3, 4],
      },
      hidden: false,
    },
    {
      display: 'npm',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [2, 4],
      },
      hidden: true,
    },
    {
      display: 'Vite',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [2, 4],
      },
      hidden: false,
    },
    {
      display: 'Vue CLI',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [2, 4],
      },
      hidden: false,
    },
    {
      display: 'Webpack',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [2, 4],
      },
      hidden: false,
    },
    {
      display: 'Browserify',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [2, 4],
      },
      hidden: true,
    },
    {
      display: 'ESLint',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [1, 2, 4],
      },
      hidden: false,
    },
    {
      display: 'Prettier',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [1, 2, 4],
      },
      hidden: false,
    },
    {
      display: 'Babel',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [1, 2, 4],
      },
      hidden: false,
    },
    {
      display: 'Istanbul/NYC',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [1, 2, 4, 8],
      },
      hidden: false,
    },
    {
      display: 'DeepSource',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [1, 2, 4],
      },
      hidden: false,
    },
    {
      display: 'Code Climate',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [1, 2, 4],
      },
      hidden: false,
    },
    {
      display: 'Chromatic',
      codingCategory: 'tool',
      languages: [],
      skills: {
        coding: [2, 4, 7],
      },
      hidden: false,
    },
    {
      display: 'Balsamiq',
      codingCategory: 'tool',
      languages: [],
      skills: {
        coding: [7],
      },
      hidden: false,
    },
    {
      display: 'Postman',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [1, 3, 4, 8],
      },
      hidden: false,
    },
    {
      display: 'NPM',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [1, 2, 8],
      },
      hidden: true,
    },
    {
      display: 'curl',
      codingCategory: 'tool',
      languages: ['Command Line'],
      skills: {
        coding: [1, 3, 4],
      },
      hidden: false,
    },
    {
      display: 'psql',
      codingCategory: 'tool',
      languages: ['SQL'],
      skills: {
        coding: [0, 1, 3, 4],
      },
      hidden: false,
    },
    {
      display: 'SchemaSpy',
      codingCategory: 'tool',
      languages: ['SQL'],
      skills: {
        coding: [0, 1, 3, 4],
      },
      hidden: true,
    },
    {
      display: 'Supabase',
      codingCategory: 'tool',
      languages: ['Command Line', 'SQL', 'JavaScript'],
      skills: {
        coding: [0, 1, 3, 4],
      },
      hidden: false,
    },
    {
      display: 'Spyder',
      codingCategory: 'tool',
      languages: ['Python'],
      skills: {
        coding: [1, 3, 4],
      },
      hidden: true,
    },
    {
      display: 'pycodestyle',
      codingCategory: 'tool',
      languages: ['Python'],
      skills: {
        coding: [1, 3, 4],
      },
      hidden: true,
    },
    {
      display: 'virtualenv/virtualenvwrapper',
      codingCategory: 'tool',
      languages: ['Python'],
      skills: {
        coding: [1, 3, 4],
      },
      hidden: true,
    },
    {
      display: 'apiDoc',
      codingCategory: 'tool',
      languages: ['Python', 'JavaScript'],
      skills: {
        coding: [1, 3, 4],
      },
      hidden: false,
    },
    {
      display: 'Okta',
      codingCategory: 'tool',
      languages: ['Command Line', 'JavaScript'],
      skills: {
        coding: [1, 2, 3, 4],
      },
      hidden: false,
    },
    {
      display: 'Cognito',
      codingCategory: 'tool',
      languages: ['Command Line', 'JavaScript'],
      skills: {
        coding: [1, 2, 3, 4],
      },
      hidden: false,
    },
    {
      display: 'Docker',
      codingCategory: 'tool',
      languages: ['Command Line', 'SQL', 'JavaScript'],
      skills: {
        coding: [1, 2, 3, 4, 9],
      },
      hidden: false,
    },
    {
      display: 'Sentry',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [2, 4],
      },
      hidden: false,
    },
    {
      display: 'Netlify',
      codingCategory: 'tool',
      languages: ['Command Line', 'JavaScript'],
      skills: {
        coding: [2, 4, 9],
      },
      hidden: false,
    },
    {
      display: 'GitHub Actions',
      codingCategory: 'tool',
      languages: ['Command Line', 'YAML'],
      skills: {
        coding: [1, 2, 3, 4, 9],
      },
      hidden: false,
    },
    {
      display: 'Azure Functions',
      codingCategory: 'tool',
      languages: ['JavaScript', 'Python'],
      skills: {
        coding: [1, 2, 3, 4, 9],
      },
      hidden: false,
    },
    {
      display: 'Azure Static Web Apps',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [1, 2, 3, 4, 9],
      },
      hidden: false,
    },
    {
      display: 'Azure API Management',
      codingCategory: 'tool',
      languages: [],
      skills: {
        coding: [3, 4],
      },
      hidden: false,
    },
    {
      display: 'Cosmos DB',
      codingCategory: 'tool',
      languages: ['SQL'],
      skills: {
        coding: [1, 2, 3, 4, 9],
      },
      hidden: false,
    },
    {
      display: 'Bitbucket Pipelines',
      codingCategory: 'tool',
      languages: ['Command Line', 'YAML'],
      skills: {
        coding: [1, 2, 3, 4, 9],
      },
      hidden: false,
    },
    {
      display: 'Heroku',
      codingCategory: 'tool',
      languages: ['Command Line'],
      skills: {
        coding: [1, 2, 3, 4, 9],
      },
      hidden: false,
    },
    {
      display: 'Heroku CI',
      codingCategory: 'tool',
      languages: ['Command Line', 'SQL', 'JavaScript'],
      skills: {
        coding: [1, 3, 4, 8, 9],
      },
      hidden: true,
    },
    {
      display: 'Selenium',
      codingCategory: 'tool',
      languages: [],
      skills: {
        coding: [8, 9],
      },
      hidden: true,
    },
    {
      display: 'Progress Rollbase',
      codingCategory: 'tool',
      languages: ['SQL', 'JavaScript'],
      skills: {
        coding: [1, 4],
      },
      hidden: true,
    },
    {
      display: 'JSON',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [1, 2, 4],
      },
      hidden: true,
    },
    {
      display: 'AJAX',
      codingCategory: 'tool',
      languages: ['JavaScript'],
      skills: {
        coding: [1, 2, 4],
      },
      hidden: true,
    },
    {
      display: 'HTTP',
      codingCategory: 'tool',
      languages: ['Python', 'JavaScript'],
      skills: {
        coding: [1, 2, 3, 4],
      },
      hidden: true,
    },
  ],
};
